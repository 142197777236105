const config = {
    // customisation
    env: 'cegeclim',
    color: '#133147',
    rgbPDF: {
        r: 0.035,
        g: 0.333,
        b: 0.502
    },
    header: {
        backgroundPosition: '',
        backgroundSize: '',
        logoContainerPadding: 0,
        logo: {
            height: '450px',
            width: '0%',
            backgroundColor: 'transparent'
        },
        title: ''
    },
    name: 'Cegeclim Energies',
    custom: {
        // ajouter une clé pour ajouter une ligne
        //1: ''
    },
    mail: 'photovoltaique@cegeclim-energies.com',
    privacy: 'https://cegeclim-energies.com/politique-de-confidentialite/',
    legal: 'https://cegeclim-energies.com/mentions-legales/',
    modalType: '', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: 0,
        hjsv: 0
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
        sendFormUrl: 'https://solution-photovoltaique.cegeclim-energies.com/sendPDF',
        fetchBill: 'https://solution-photovoltaique.cegeclim-energies.com/estimated-invoice',
        fetchEstimation: 'https://solution-photovoltaique.cegeclim-energies.com/pre-calculate',

    // external urls
    achilleUrl: '',
};

export default config;

